export default {
  computed: {
    roofRailLockingSet() {
      let product = this.products.find(p => p.type === 'roofRailLockingSet')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      // Add properties
      fullProduct.singlepack = this.selections.roofRailLengths.value.length;
      fullProduct.amount = fullProduct.singlepack;
      fullProduct.unit = 'st';
      fullProduct.sort = 10;

      return fullProduct;
    }
  }
};
