export default {
  computed:{  
    roofRail () {
      const roofRailType = this.safetySystem == 'SAFE' ? 'roofRail' : 'roofRailPW';
      let product = this.products.find(p => p.type === roofRailType)
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      
      let amount = this.amountOfProducts(this.selections.roofRailLengths.value, fullProduct["length"]/1000)
      fullProduct.amount = amount

      fullProduct.singlepack = amount
      fullProduct.bigpack = null
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
      fullProduct.sort = 8

      return fullProduct
    },
  },
  methods: {}
}
