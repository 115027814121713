export default {
  computed:{    
    footplate () {
      let product = this.products.find(p => p.type === 'footplate')
      let variant = product.variants.find(v => v.fits.includes(this.selections.rooftile.value))
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;

      // Calculate amount     
      let amount = 
        this.snowSafetyConsole.amount + 
        this.roofBridgeConsole.amount + 
        this.roofRailConsole.amount + 
        this.roofHatchConsole.amount * 4
            
      let bigpack = this.bigpack(fullProduct, amount)
      
      // Add properties
      fullProduct.amount = amount
      fullProduct.singlepack = bigpack[0]
      fullProduct.bigpack = bigpack[1]
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
      fullProduct.sort = 98

      return fullProduct
    }
  },
  methods: {}
}
