export default {
  computed: {
    roofBridge () {
      const roofBridgeKey = this.safetySystem == 'SAFE' ? 'roofBridge' : 'roofBridgePW';
      let product = this.products.find(p => p.type === roofBridgeKey)
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      
      let amount = this.amountroofBridge(this.selections.roofBridgeLengths.value).amountLong
      fullProduct.amount = amount

      fullProduct.singlepack = amount
      fullProduct.bigpack = null
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
      fullProduct.sort = 3

      return fullProduct
    },
    roofBridgeShort () {
      const roofBridgeKey = this.safetySystem == 'SAFE' ? 'roofBridgeShort' : 'roofBridgeShortPW';
      let product = this.products.find(p => p.type === roofBridgeKey)
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      
      let amount = this.amountroofBridge(this.selections.roofBridgeLengths.value).amountShort
      fullProduct.amount = amount
      
      fullProduct.singlepack = amount
      fullProduct.bigpack = null
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
      fullProduct.sort = 4

      return fullProduct
    },
  },
  methods: {
    amountroofBridge(lengthsArr = []) {
      let lengthLong = this.safetySystem == 'SAFE' ? 2.1 : 2.31
      let lengthShort = this.safetySystem == 'SAFE' ? 1.05 : 1.19
      let amountLong = 0
      let amountShort = 0
      let tolerance = 0.3

      lengthsArr.forEach((distance) => {
        if (!isNaN(Number(distance))) {
          let numDistance = Number(distance);
  
          while (numDistance > lengthLong + lengthShort) { // Minska numDistance med lengthLong tills det är mindre än eller lika med 3.15
            amountLong++;
            numDistance -= lengthLong;
          }
  
          if (numDistance <= lengthShort + tolerance) {  // Kontrollera om numDistance är mindre än eller lika med lengthShort + tolerance
            amountShort++;
          } else if (numDistance <= lengthLong + tolerance) { // Kontrollera om numDistance är mindre än eller lika med lengthLong + tolerance
            amountLong++;
          } else if (numDistance <= lengthLong + lengthShort + tolerance) { // Kontrollera om numDistance är mindre än eller lika med lengthLong + lengthShort + tolerance
            amountLong++;
            amountShort++;
          }
        }
      });
  
      return {amountLong, amountShort} //Returnerar antal korta och longa till summering
    }
  }
}
