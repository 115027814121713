export default {
  computed: {
    stagBridge() {
      let product = this.products.find(p => p.type === 'stagBridge')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      const amount = this.selections.roofBridgeLengths.value.length;

      // Add properties
      fullProduct.singlepack = amount;
      fullProduct.amount = amount;
      fullProduct.unit = 'st';
      fullProduct.sort = 6

      return fullProduct;
    }
  }
};
