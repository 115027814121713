export default {
  computed:{    
    roofHatchConsole () {
      let product = this.products.find(p => p.type === 'roofHatchSafetyConsole')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;

      // Calculate amount
      let amount =  Number(this.selections.roofHatchSafetyAmount.value)
      
      if (this.safetySystem == 'PW') {
        fullProduct = this.getPWConsoleProduct();
        amount = 4 * Number(this.selections.roofHatchSafetyAmount.value);
      }

      // Add properties
      fullProduct.amount = amount
      fullProduct.singlepack = amount
      fullProduct.bigpack = null
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
      fullProduct.sort = 7

      return fullProduct
    }
  },
  methods: {}
}
