export default {
  computed: {
    jointBolt() {
      let product = this.products.find(p => p.type === 'jointBolt')
      let variant = product.variants.find(v => v.color == 'Galvad')
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      const amount = Math.ceil((this.snowProtection.amount - 1) / 2)
        + Math.ceil((this.roofBridge.amount + this.roofBridgeShort.amount - 1) / 2)
        + this.roofBridgeConsole.amount;
      console.log('jointBolt', Math.ceil((this.snowProtection.amount - 1) / 2), Math.ceil((this.roofBridge.amount - 1) / 2), this.roofBridgeConsole.amount);

      // Add properties
      fullProduct.singlepack = amount;
      fullProduct.amount = fullProduct.singlepack;
      fullProduct.unit = 'pkt';
      fullProduct.sort = 99

      return fullProduct;
    }
  }
};
