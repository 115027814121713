export default {
  computed: {
    snowSafetyConsole() {
      let product = this.products.find(p => p.type === 'snowSafetyConsole')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      if (this.safetySystem == 'PW') {
        fullProduct = this.getPWConsoleProduct();
      }

      // Calculate amount
      // AFFECTS NUM TRACKTILES
      let rooftileCC = this.rooftile.cc
      let maxDistanceConsole = this.rooftile.maxDistanceConsole



      let distanceConsole = this.maxDistance < maxDistanceConsole ?
        maxDistanceConsole / 1000 :
        Math.floor(this.maxDistance / rooftileCC) * rooftileCC / 1000

      // From productCalcUtil imported in summarystep
      let rows = this.rowsSnowprotection

      let amount = 0
      let roofSafetyLengths = this.selections.roofSafetyLengths.value
      let snowProtectionLength = this.snowProtection['length'] / 1000

      /*
//new calc 20240528
roofSafetyLengths.forEach((distance) => {
  if (!isNaN(Number(distance))) {
    let length = Math.floor(Number(distance) / snowProtectionLength) * snowProtectionLength //Räknar ut hur många safe som behövs
    let lengthDiff = distance - length //Räknar ut diffen safe
    let totalSafe = 0
    if (lengthDiff > 0.5) {
      totalSafe += Math.floor(Number(distance) / snowProtectionLength) + 1; //Ger antal safe
    } else {
      totalSafe += Math.floor(Number(distance) / snowProtectionLength); // Ger antal safe
    }
    let totalconsole = (totalSafe * snowProtectionLength).toFixed(2) // Räknar med värdet som antalsafe gånger konsollängd Ta bort decimaler (spara 2)
    amount += Math.ceil((totalconsole) / (distanceConsole)) + 1 //Delar antal konsoler med längden avrundat uppåt
  }
})      
*/  
//new calc 20240919
roofSafetyLengths.forEach((distance) => {
        if (!isNaN(Number(distance))) {
          let length = Math.floor(Number(distance) / snowProtectionLength) * snowProtectionLength //Räknar ut hur många safe som behövs
          let lengthDiff = (distance - length).toFixed(2) //Räknar ut diffen safe
          let totalSafe = 0
          if (lengthDiff > 0.5) {
            totalSafe += Math.floor(Number(distance) / snowProtectionLength) + 1; //Ger antal safe
          } else {
            totalSafe += Math.floor(Number(distance) / snowProtectionLength); // Ger antal safe
          }
          let totalconsole = (totalSafe * snowProtectionLength).toFixed(2) // Räknar med värdet som antalsafe gånger konsollängd Ta bort decimaler (spara 2)
          let consoles = (totalconsole) / (distanceConsole) //Delar antal konsoler med längden
          const buffer = this.safetySystem == 'SAFE' ? 0.3 : 0.4
          let consolesFixed = consoles.toFixed(2) - buffer //Avrundar till två decimaler och tillagt att -0.3 vid varje gång vi beräknar tbh
          amount += Math.ceil((consolesFixed)) + 1 //Avrundar antalet konsoler uppåt till närmsta heltal och adderar en extra
        }
      })
      amount = amount * rows

      // Add properties
      fullProduct.amount = amount
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st';

      if (this.safetySystem == 'SAFE') {
        let bigpack = this.bigpack(fullProduct, amount);
        fullProduct.singlepack = bigpack[0];
        fullProduct.bigpack = bigpack[1];
        fullProduct.sort = 2
      }else {
        fullProduct.singlepack = amount;
      }

      return fullProduct
    }
  },
  methods: {}
}
