export default {
  computed: {
    roofRailScrews() {
      let product = this.products.find(p => p.type === 'roofRailScrews')
      let variant = product.variants.find(v => v.color == 'Galvad')
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      const roofRailType = this.safetySystem == 'SAFE' ? 'roofRail' : 'roofRailPW';
      let roofRailProduct = this.products.find(p => p.type === roofRailType);
      let joints = this.amountOfJoints(this.selections.roofRailLengths.value, roofRailProduct['length']/1000);

      // Add properties
      fullProduct.singlepack = Math.ceil(joints / 10);
      fullProduct.amount = fullProduct.singlepack;
      fullProduct.unit = 'st';
      fullProduct.sort = 15

      return fullProduct;
    }
  }
};
