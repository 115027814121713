export default {
  computed: {
    snowLockingSet() {
      let product = this.products.find(p => p.type === 'snowLockingSet')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      // Add properties
      fullProduct.singlepack = this.snowSafetyConsole.amount;
      fullProduct.amount = fullProduct.singlepack;
      fullProduct.unit = 'st';
      fullProduct.sort = 2

      return fullProduct;
    }
  }
};
