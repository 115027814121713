export default {
  computed: {
    roofHatchRail() {
      let product = this.products.find(p => p.type === 'roofHatchRail')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      let amount = this.selections.roofHatchSafetyAmount.value

      // Add properties
      fullProduct.singlepack = amount;
      fullProduct.amount = amount;
      fullProduct.unit = 'st';
      fullProduct.sort = 2

      return fullProduct;
    }
  }
};
