export default {
  computed: {
    battenScrews() {
      let product = this.products.find(p => p.type === 'battenScrews')
      let variant = product.variants.find(v => v.color == 'Galvad')
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      const consoles = this.snowSafetyConsole.amount + this.roofBridgeConsole.amount + this.roofRailConsole.amount + 4 * +this.selections.roofHatchSafetyAmount.value;
      const consoleArtno = this.snowSafetyConsole.artNumber;
      const amountPerConsole = consoleArtno.startsWith('1376') ? 0.5 : 1;

      // Add properties
      fullProduct.singlepack = Math.ceil(amountPerConsole * consoles);
      fullProduct.amount = fullProduct.singlepack;
      fullProduct.unit = 'st';
      fullProduct.sort = 98

      return fullProduct;
    }
  }
};
